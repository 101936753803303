@use '../../styles' as *;

.result--item__name {
    .last-msg{
        @include pt(0.25);
        font-size: $font-size-sm;
        color: $gray-light;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}