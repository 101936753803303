@use '../../styles' as *;

.chat-input {
    @include p(1);
    background-color: $white;
    display: flex;
    gap: 1rem;
    max-height: 50%;
    box-shadow: 0px -4px 8px rgba($theme-dark, 0.1);
    position: relative;

    .input-message {
        flex: 1 0 0;
        overflow-y: auto;

        &__image {
            max-height: 80%;
            &.invisible {
                display: none;
            }
        }

        &__text {
            min-height: 45px;
            display: flex;
            align-items: center;
            outline: none;
            word-break: break-word;
            font-size: $font-size-base;
        }

        &__text:empty {
            line-height: 45px;
        }

        &__text:empty:after {
            content: 'Type your message here...';
            color: $gray-light;
        }
    }
    
    .input-image {
        @include p(1);
        label {
            cursor: pointer;
            color: $theme-light;
            &:hover {
                color: $theme;
            }
        }
        input {
            display: none;
        }
    }
    .btn-theme { 
        height: 45px;
        width: 45px;
        @include p(1);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}