@use '../styles/' as *;

.hr-text {
    @include my(2);
    display: flex;
    text-transform: uppercase;
    &::before, &::after {
        content: '';
        flex-grow: 1;
        border-bottom: 1px solid;
        margin: auto 1rem;
    }
}

.other-login {
    @include my(2);
    @include mxAuto;
    display: flex;
    max-width: 250px;
    justify-content: space-between;
    .btn-app {
        @include p(1);
        font-size: 1.5rem;
        line-height: 0;
        vertical-align: middle;
        border-radius: 50%;
        color: $white;

        &--google {
            background-color: $white;
        }
        &--facebook {
            background-color: #4267B2;
        }
        &--github {
            background-color: $black;
        }
        &:hover {
            box-shadow: 0 0 10px 2px rgba($white, 0.5);
            transform: scale(1.05);
            transition: all 200ms;
        }
    }
}

