@use '../styles/' as *;

.chat {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    @include md {
        .toggle {
            display: none;
        }
    } 
}
