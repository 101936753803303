@use '../styles/' as *;

.navbar {
    width: 90%;
    max-width: 300px;
    height: 100%;
    background-color: $theme-dark;
    color: rgba($white, 0.7);
    z-index: 99;
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(-100%);
    transition: all 500ms;

    display: flex;
    flex-direction: column;

    &--open {
        transform: none;
    }
    @include md {
        height: auto;
        position: relative;
        transform: none;
    } 
}

.nav-loading {
    @include p(1);
    flex-grow: 1;
    font-size: $font-size-xxl;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @keyframes rotate {
        from {
            transform: rotate(0deg);
        }

        to {
            transform: rotate(360deg);
        }
    }
    svg {
        animation: rotate linear infinite 3s;
    }
}