@use '../../styles' as *;

.nav-search {
    @include m(1);
    @include p(1);
    display: flex;
    align-items: center;
    background-color: white;
    border-radius: 30px;

    input {
        flex: 1 1 0;
        border: none;
        outline: none;
        font-size: $font-size-base;
    }

    .btn {
        height: 26px;
        &.search {
            color: $theme-light;
        }

        &.cancel {
            color: $error;
            opacity: 50%;
            &:hover {
                opacity: 100%;
            }
            &.hidden {
                opacity: 0%;  
                cursor: auto;              
            }
        }
    }

}